"use client";

import { GetHomeQuery } from "@/graphql/schema";
import Image from "next/image";
import React from "react";

export default function Customers({ homes }: GetHomeQuery) {
  return (
    <div className="cs-container py-16 md:py-32">
      {/* First Row with padding-left */}
      <div className="mb-12 grid grid-cols-2 justify-items-center gap-12 md:mb-16 md:grid-cols-4 md:justify-items-end md:pl-24 lg:gap-16">
        {homes[0].customers.slice(0, 4).map((logo, index) => (
          <div key={index} className="flex h-12 w-24 items-center justify-center md:h-16 md:w-32">
            <img
              src={logo?.url}
              alt={logo?.altText || ""}
              width={100}
              height={50}
              className="mx-auto w-[140px] object-contain object-right md:w-[150px]"
            />
          </div>
        ))}
      </div>
      {/* Second Row without padding-left */}
      <div className="grid grid-cols-2 justify-items-center gap-12 md:grid-cols-4 md:justify-items-start lg:gap-16">
        {homes[0].customers.slice(4).map((logo, index) => (
          <div key={index} className="flex h-12 w-24 items-center justify-center md:h-16 md:w-32">
            <img
              src={logo?.url}
              alt={logo?.altText || ""}
              width={100}
              height={50}
              className="mx-auto w-[140px] object-contain object-right md:w-[150px]"
            />
          </div>
        ))}
      </div>
      {/* <div className="mb-24 grid w-full grid-cols-2 !items-center gap-24 md:grid-cols-[100px_1fr] md:gap-36">
        {homes[0].customers.slice(0, 4).map((customer, i) => (
          <div key={i}>
            <img
              src={customer?.url || ""}
              alt={customer?.altText || ""}
              width={1500}
              height={1500}
              className="mx-auto w-[140px] object-contain object-right md:w-[150px]"
            />
          </div>
        ))}
      </div>
      <div className="grid grid-cols-2 flex-col !items-center gap-24 md:flex md:flex-row">
        {homes[0].customers.slice(4, 8).map((customer, i) => (
          <div key={i}>
            <img
              src={customer?.url || ""}
              alt={customer?.altText || ""}
              width={1500}
              height={1500}
              className="mx-auto w-[140px] object-contain object-right md:w-[150px]"
            />
          </div>
        ))}
      </div> */}
    </div>
  );
}
